var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1 ml-4",attrs:{"headers":_vm.headers,"items":_vm.users,"page":_vm.page,"items-per-page":_vm.usersPerPage,"server-items-length":_vm.totalUsers,"hide-default-footer":"","sort-by":"name"},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Users")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"700px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.showCreate)?_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"green","dark":""}},on),[_vm._v("New User")]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('validation-observer',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Firstname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"First Name","type":"text","outlined":"","name":"Firstname","error-messages":errors,"color":"grey","filled":""},model:{value:(_vm.editedUser.firstName),callback:function ($$v) {_vm.$set(_vm.editedUser, "firstName", $$v)},expression:"editedUser.firstName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Last Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Last Name","type":"text","outlined":"","name":"LastName","error-messages":errors,"color":"grey","filled":""},model:{value:(_vm.editedUser.lastName),callback:function ($$v) {_vm.$set(_vm.editedUser, "lastName", $$v)},expression:"editedUser.lastName"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|numeric|min:10","name":"Telephone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-n6",attrs:{"dense":"","label":"Telephone","type":"telephone","outlined":"","error-messages":errors,"color":"grey","name":"Telephone","filled":""},model:{value:(_vm.editedUser.phone),callback:function ($$v) {_vm.$set(_vm.editedUser, "phone", $$v)},expression:"editedUser.phone"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-n6",attrs:{"dense":"","label":"Email","type":"email","outlined":"","name":"email","error-messages":errors,"color":"grey","filled":""},on:{"input":_vm.setUsername},model:{value:(_vm.editedUser.email),callback:function ($$v) {_vm.$set(_vm.editedUser, "email", $$v)},expression:"editedUser.email"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Position"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mt-n6",attrs:{"dense":"","items":_vm.positions,"label":"Position","outlined":"","item-text":"name","offset-y":"","name":"Position","error-messages":errors,"color":"grey","filled":""},model:{value:(_vm.editedUser.position),callback:function ($$v) {_vm.$set(_vm.editedUser, "position", $$v)},expression:"editedUser.position"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mt-n6",attrs:{"dense":"","items":_vm.centers,"label":"Center","outlined":"","item-text":"name","item-value":"id","name":"Center","error-messages":errors,"color":"grey","filled":""},model:{value:(_vm.editedUser.center),callback:function ($$v) {_vm.$set(_vm.editedUser, "center", $$v)},expression:"editedUser.center"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-select',{staticClass:"mt-n6",attrs:{"items":_vm.roles,"item-text":"name","chips":"","label":"Roles","multiple":"","outlined":"","deletable-chips":"","color":"grey","filled":""},model:{value:(_vm.editedUser.roles),callback:function ($$v) {_vm.$set(_vm.editedUser, "roles", $$v)},expression:"editedUser.roles"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"mt-n6",attrs:{"dense":"","label":"Username","type":"text","outlined":"","name":"Username","color":"grey","disabled":"","placeholder":_vm.editedUser.username,"filled":""},model:{value:(_vm.editedUser.username),callback:function ($$v) {_vm.$set(_vm.editedUser, "username", $$v)},expression:"editedUser.username"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"vid":"confirmation","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.edit)?_c('v-text-field',{staticClass:"mt-n6",attrs:{"dense":"","label":"Passwords","outlined":"","name":"Password","hint":_vm.hintMesssage,"persistent-hint":"","error-messages":errors,"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"color":"grey","filled":""},on:{"click:append":function($event){$event.preventDefault();_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.editedUser.password),callback:function ($$v) {_vm.$set(_vm.editedUser, "password", $$v)},expression:"editedUser.password"}}):_vm._e()]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('validation-provider',{attrs:{"rules":"confirmed:confirmation","name":"Confirmed Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.edit)?_c('v-text-field',{staticClass:"mt-n6",attrs:{"dense":"","label":"Confirm Password","outlined":"","name":"Confirmed Password","error-messages":errors,"append-icon":_vm.showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showConfirmPassword ? 'text' : 'password',"color":"grey","filled":""},on:{"click:append":function($event){$event.preventDefault();_vm.showConfirmPassword = !_vm.showConfirmPassword}},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}):_vm._e()]}}])})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"success ml-4 mb-4",attrs:{"text":"","type":"submit"}},[_vm._v("Save")]),_c('v-btn',{staticClass:"mb-4",attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-spacer')],1)],1)])],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.showEdit)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editUser(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(item.status === 'LOCKED' && _vm.showLock)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.preventDefault();return _vm.toggleLock(item)}}},[_vm._v(" mdi-lock ")]):_vm._e(),(item.status === 'ACTIVE' && _vm.showLock)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){$event.preventDefault();return _vm.toggleLock(item)}}},[_vm._v(" mdi-lock-open-variant ")]):_vm._e(),(_vm.showDelete)?_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.userDialog(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}}])}),_c('v-snackbar',{attrs:{"top":"","color":_vm.color,"timeout":_vm.timeout},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"blue","icon":""},on:{"click":function($event){return _vm.setSnackbar(false)}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" ")]),_c('v-dialog',{attrs:{"hide-overlay":"","max-width":"500px"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',{attrs:{"tile":"","outlined":""}},[_c('v-card-text',[_c('p',[_vm._v("Are you sure you want to delete this user ?")]),_c('v-btn',{attrs:{"link":"","small":"","color":"blue"},on:{"click":_vm.deleteUser}},[_vm._v("Yes")]),_c('v-btn',{attrs:{"link":"","small":"","color":"blue"},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v("No")])],1)],1)],1),_c('div',{staticClass:"pt-2 px-12"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-select',{attrs:{"items":_vm.selectUsersPerPage,"label":"Users per page","dense":"","filled":""},model:{value:(_vm.usersPerPage),callback:function ($$v) {_vm.usersPerPage=$$v},expression:"usersPerPage"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"10"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount,"circle":"","total-visible":"7","color":"blue"},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }