<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="users"
      :page.sync="page"
      :items-per-page="usersPerPage"
      :server-items-length="totalUsers"
      hide-default-footer
      @page-count="pageCount = $event"
      sort-by="name"
      class="elevation-1 ml-4"
    >
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-toolbar-title>Users</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="700px" persistent>
            <template v-slot:activator="{ on }">
              <v-btn color="green" dark class="mb-2" v-on="on" v-if="showCreate"
                >New User</v-btn
              >
            </template>
            <v-card>
              <validation-observer ref="form">
                <form @submit.prevent="onSubmit">
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                            name="Firstname"
                          >
                            <v-text-field
                              dense
                              label="First Name"
                              type="text"
                              outlined
                              v-model="editedUser.firstName"
                              name="Firstname"
                              :error-messages="errors"
                              color="grey"
                              filled
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6"
                          ><validation-provider
                            v-slot="{ errors }"
                            rules="required"
                            name="Last Name"
                          >
                            <v-text-field
                              dense
                              label="Last Name"
                              type="text"
                              outlined
                              v-model="editedUser.lastName"
                              name="LastName"
                              :error-messages="errors"
                              color="grey"
                              filled
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|numeric|min:10"
                            name="Telephone"
                          >
                            <v-text-field
                              dense
                              label="Telephone"
                              type="telephone"
                              outlined
                              v-model="editedUser.phone"
                              class="mt-n6"
                              :error-messages="errors"
                              color="grey"
                              name="Telephone"
                              filled
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required|email"
                            name="email"
                          >
                            <v-text-field
                              dense
                              label="Email"
                              type="email"
                              outlined
                              v-model="editedUser.email"
                              class="mt-n6"
                              @input="setUsername"
                              name="email"
                              :error-messages="errors"
                              color="grey"
                              filled
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                            name="Position"
                          >
                            <v-select
                              dense
                              :items="positions"
                              label="Position"
                              outlined
                              item-text="name"
                              v-model="editedUser.position"
                              offset-y
                              class="mt-n6"
                              name="Position"
                              :error-messages="errors"
                              color="grey"
                              filled
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="required"
                            name="Center"
                          >
                            <v-select
                              dense
                              :items="centers"
                              label="Center"
                              outlined
                              item-text="name"
                              item-value="id"
                              v-model="editedUser.center"
                              class="mt-n6"
                              name="Center"
                              :error-messages="errors"
                              color="grey"
                              filled
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <v-select
                            v-model="editedUser.roles"
                            :items="roles"
                            item-text="name"
                            chips
                            label="Roles"
                            multiple
                            outlined
                            deletable-chips
                            class="mt-n6"
                            color="grey"
                            filled
                          ></v-select>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <v-text-field
                            dense
                            label="Username"
                            type="text"
                            v-model="editedUser.username"
                            outlined
                            class="mt-n6"
                            name="Username"
                            color="grey"
                            disabled
                            :placeholder="editedUser.username"
                            filled
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <validation-provider
                            vid="confirmation"
                            v-slot="{ errors }"
                            name="Password"
                          >
                            <v-text-field
                              dense
                              label="Passwords"
                              v-model="editedUser.password"
                              outlined
                              name="Password"
                              :hint="hintMesssage"
                              persistent-hint
                              :error-messages="errors"
                              class="mt-n6"
                              :append-icon="
                                showPassword ? 'mdi-eye' : 'mdi-eye-off'
                              "
                              :type="showPassword ? 'text' : 'password'"
                              @click:append.prevent="
                                showPassword = !showPassword
                              "
                              color="grey"
                              filled
                              v-if="edit"
                            />
                          </validation-provider>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <validation-provider
                            v-slot="{ errors }"
                            rules="confirmed:confirmation"
                            name="Confirmed Password"
                          >
                            <v-text-field
                              dense
                              label="Confirm Password"
                              v-model="confirmPassword"
                              outlined
                              name="Confirmed Password"
                              :error-messages="errors"
                              class="mt-n6"
                              :append-icon="
                                showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'
                              "
                              :type="showConfirmPassword ? 'text' : 'password'"
                              @click:append.prevent="
                                showConfirmPassword = !showConfirmPassword
                              "
                              color="grey"
                              filled
                              v-if="edit"
                            />
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-btn class="success ml-4 mb-4" text type="submit"
                      >Save</v-btn
                    >
                    <v-btn
                      class="mb-4"
                      color="blue darken-1"
                      text
                      @click="close"
                      >Cancel</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </form>
              </validation-observer>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editUser(item)" v-if="showEdit">
          mdi-pencil
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click.prevent="toggleLock(item)"
          v-if="item.status === 'LOCKED' && showLock"
        >
          mdi-lock
        </v-icon>
        <v-icon
          small
          class="mr-2"
          @click.prevent="toggleLock(item)"
          v-if="item.status === 'ACTIVE' && showLock"
        >
          mdi-lock-open-variant
        </v-icon>
        <v-icon small @click.stop="userDialog(item)" v-if="showDelete">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" top :color="color" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" icon v-bind="attrs" @click="setSnackbar(false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="deleteDialog" hide-overlay max-width="500px">
      <v-card tile outlined>
        <v-card-text>
          <p>Are you sure you want to delete this user ?</p>
          <v-btn link small color="blue" @click="deleteUser">Yes</v-btn>
          <v-btn link small color="blue" @click="deleteDialog = false"
            >No</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="pt-2 px-12">
      <v-row>
        <v-col cols="12" sm="2">
          <v-select
            :items="selectUsersPerPage"
            v-model="usersPerPage"
            label="Users per page"
            dense
            filled
          ></v-select>
        </v-col>
        <v-col cols="12" sm="10">
          <v-pagination
            v-model="page"
            :length="pageCount"
            circle
            total-visible="7"
            color="blue"
          ></v-pagination>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import User from '@/models/User'
export default {
  data () {
    return {
      hintMesssage:
        'password complexity, at least: 8 characters, one capital letter and avoid sequential numbers(123...)',
      privileges: null,
      showEdit: false,
      showDelete: false,
      showCreate: false,
      showLock: false,
      dialog: false,
      deleteDialog: false,
      positionIndex: null,
      positionItem: null,
      alertType: null,
      edit: true,
      headers: [
        {
          text: 'First Name',
          align: 'start',
          sortable: false,
          value: 'firstName'
        },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Phone', value: 'phone' },
        { text: 'Email', value: 'email' },
        { text: 'Center', value: 'center' },
        { text: 'Position', value: 'position' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      users: [],
      roles: [],
      centers: [],
      positions: [],
      username: '',
      confirmPassword: '',
      showPassword: false,
      showConfirmPassword: false,
      editedIndex: -1,
      editedUser: new User(),
      defaultItem: new User(),
      selectUsersPerPage: [2, 5, 10, 15, 20, 25, 30],
      page: 1,
      pageCount: 0,
      usersPerPage: 20,
      totalUsers: 0
    }
  },

  computed: {
    formTitle () {
      return this.editedIndex === -1 ? 'Add User' : 'Edit User'
    },
    ...mapState('store', [
      'snackbar',
      'color',
      'text',
      'timeout',
      'currentUser'
    ])
  },
  mounted () {
    if (this.showCreate || this.showEdit) {
      this.loadDependencies()
    }
    this.loadUsers()
  },

  watch: {
    dialog (val) {
      val || this.close()
    },
    page: {
      handler () {
        this.loadUsers()
      }
    },
    usersPerPage: {
      handler () {
        this.page = 1
        this.loadUsers()
      }
    }
  },

  created () {
    this.privileges = this.currentUser.privileges
    this.checkPrivileges()
  },
  destroyed () {
    this.setSnackbar(false)
  },

  methods: {
    ...mapMutations('store', {
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT'
    }),
    loadCenters () {
      if (this.privileges.includes('ALL_AUTHORITIES')) {
        this.$centerService.fetchAllList().then((response) => {
          if (!response.status) this.centers = response
          else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
      } else {
        this.centers = [this.currentUser.center]
        this.editedUser.center = this.currentUser.center
      }
    },
    loadPositions () {
      this.$userService.fetchAllPositionsList().then((positions) => {
        this.positions = positions
      })
    },
    loadRoles () {
      this.$userService.fetchAllRolesList().then((response) => {
        if (!response.status) {
          this.roles = response
        } else {
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },
    loadUsers () {
      const pageNumber = this.page - 1
      this.$userService
        .fetchAllUsers(pageNumber, this.usersPerPage)
        .then((response) => {
          if (!response.status) {
            this.users = response.content
            this.totalUsers = response.totalElements
          } else {
            // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
            this.setSnackbar(true)
            this.setColor('red')
            this.setText(response.message)
          }
        })
    },
    editUser (item) {
      this.edit = false
      this.editedIndex = this.users.indexOf(item)
      this.$userService.fetchSingleUser(item.id, 'expand').then((response) => {
        if (response.id) {
          this.editedUser = response
        } else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
      this.dialog = true
    },

    userDialog (item) {
      this.userIndex = this.users.indexOf(item)
      this.userItem = item
      this.deleteDialog = true
    },

    deleteUser () {
      this.deleteDialog = false
      this.$userService.deleteUser(this.userItem.id).then((response) => {
        if (response.status === 'success') {
          this.users.splice(this.userIndex, 1)
          this.deleteDialog = false
        } else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },

    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedUser = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    onSubmit () {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return
        }
        if (this.editedIndex > -1) {
          this.updateUser()
        } else this.createUser()
        this.$nextTick(() => {
          this.$refs.form.reset()
        })
      })
    },
    setUsername (event) {
      this.editedUser.username = this.editedUser.email.split('@')[0]
    },
    updateUser () {
      const deletedUserArray = this.users.splice(this.editedIndex, 1)
      this.$userService.saveUser(this.editedUser).then((response) => {
        if (response.id) {
          this.users.splice(this.editedIndex, 0, response)
          this.close()
        } else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
          this.users.splice(this.editedIndex, 0, deletedUserArray[0])
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },
    createUser () {
      this.$userService.saveUser(this.editedUser).then((response) => {
        if (response.id) {
          this.users.push(response)
          this.close()
        } else {
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },
    toggleLock (item) {
      this.$userService.lockUser(item.id).then((response) => {
        if (response.status === 'success') {
          item.status = item.status === 'LOCKED' ? 'ACTIVE' : 'LOCKED'
          this.errorMessage =
            item.status === 'LOCKED'
              ? 'User is successfully locked'
              : 'User is successfully unlocked'
          this.alert = true
          this.alertDialog = true
          this.alertType = 'success'
        } else {
          // this.$authenticationService.checkAuthentication(response.status, this.$router, this.$store)
          this.setSnackbar(true)
          this.setColor('red')
          this.setText(response.message)
        }
      })
    },
    checkPrivileges () {
      this.privileges.includes('CREATE_USERS') ||
      this.privileges.includes('ALL_AUTHORITIES')
        ? (this.showCreate = true)
        : (this.showCreate = false)
      this.privileges.includes('UPDATE_USERS') ||
      this.privileges.includes('ALL_AUTHORITIES')
        ? (this.showLock = true)
        : (this.showLock = false)
      this.privileges.includes('UPDATE_USERS') ||
      this.privileges.includes('ALL_AUTHORITIES')
        ? (this.showEdit = true)
        : (this.showEdit = false)
      this.privileges.includes('DELETE_USERS') ||
      this.privileges.includes('ALL_AUTHORITIES')
        ? (this.showDelete = true)
        : (this.showDelete = false)
    },
    loadDependencies () {
      this.loadCenters()
      this.loadPositions()
      this.loadRoles()
    }
  }
}
</script>
